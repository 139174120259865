<script setup>
import lightBg from '@/Assets/auth3-light.png'
import darkBg from '@/Assets/auth3-dark.png'
import ApplicationLogo from '@/Components/ApplicationLogo.vue'
import { Link, usePage } from '@inertiajs/vue3'
import { trans, wTrans } from 'laravel-vue-i18n'
import dayjs from 'dayjs'

const page = usePage()

const copyright = page.props.organizationProfile?.data?.name ?? wTrans('Codeshaper')
</script>

<template>
  <div class="loginwrapper">
    <div class="lg-inner-column">
      <img class="absolute top-0 left-0 w-full h-full light:hidden" :src="darkBg" />
      <img class="absolute top-0 left-0 w-full h-full dark:hidden" :src="lightBg" />
      <div class="right-column relative">
        <div class="h-full flex flex-col justify-center items-center gap-6">
          <div
            class="max-w-xl md:p-10 p-7 mx-auto w-full h-max flex flex-col justify-center bg-white dark:bg-slate-800 rounded-2xl"
          >
            <div class="mb-4 lg:mb-6 w-full  flex items-center justify-between">
              <Link href="/">
                <ApplicationLogo class="h-12 fill-current text-gray-500" />
              </Link>
            </div>
            <div>
              <slot />
            </div>
          </div>
          <div class="text-xs font-normal dark:text-slate-400 z-50 pb-10 text-center">
            {{ trans('Copyright') }} {{ dayjs().year() }} &copy; {{ copyright }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.loginwrapper {
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  min-height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
  flex-basis: 100%;
}

.loginwrapper .lg-inner-column {
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow-y: auto;
}

.loginwrapper .left-column {
  display: none;
  flex: 1 1 0;
  overflow: hidden;
  --tw-bg-opacity: 1;
}

.loginwrapper .right-column {
  flex: 1 1 0;
}

.ant-carousel .slick-dots-bottom {
  bottom: -50px;
}

@media (min-width: 1024px) {
  .loginwrapper .left-column {
    display: block;
  }
}
</style>
